export const navigation = [
  {
    to: '/',
    label: 'Home',
    exact: true
  },
  {
    to: '/works',
    label: 'Works',
    exact: false
  },
  {
    to: '/events',
    label: 'News & Events',
    exact: false
  },
  {
    to: '/guildhianmusic',
    label: 'Guildhian Music',
    exact: false
  },
  {
    to: '/contact',
    label: 'Contact',
    exact: false
  }
]
