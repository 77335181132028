import React from 'react';
import Typography from '@material-ui/core/Typography';

export const Contact = () => (
    <div>
        <Typography variant="h6">Ordering</Typography>
        <div>
            <ul>
                <li>Scores in PDF format may be downloaded from this site free of charge, with the proviso that you may
                    not sell any of this music in any form, physical or electronic.
                </li>
                <li>We would appreciate having notice of all public performances, and a copy of the program emailed to
                    the address below for reporting purposes to ASCAP
                </li>
                <li>The CDs <i>Red Poppy and the Yellow Rose</i> and <i>Remembering Friends</i> may be ordered through
                    the link below. Please inquire about price and availability.
                </li>
                <li>To order printed copies of score and parts, order parts electronically, for other information, or to
                    contact James Scott Balentine, please send an email to <a
                        href="mailto:guildhian@sbcglobal.net">guildhian@sbcglobal.net</a></li>
            </ul>
        </div>
        <Typography variant="h6">You may also contact Guildhian Music via USPS at:</Typography>
        <div>23860 Iron Horse</div>
        <div>San Antonio, TX 78255</div>
    </div>
);
